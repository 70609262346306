import { common } from './en/common'
import { features } from './en/features'
import { layouts } from './en/layouts'
import { pages } from './en/pages'

export const en = {
  pages: pages,
  features: features,
  layouts: layouts,
  afterConfirm: {
    attendee: '{number} attendee(s)',
    header: {
      canceled: {
        description: 'Spir sent an email to all attendees.',
        'description-mobile': 'Spir sent an email to all attendees.',
        title: 'Event canceled'
      },
      normal: {
        description: 'Spir sent an email to all attendees.',
        'description-mobile': 'Spir sent an email to all attendees.',
        title: 'Event confirmed'
      }
    },
    confirmUrl: {
      link: "If you would like to reschedule, please apply <a href='{href}' target='_blank' rel='noopener noreferrer'>here.</a>",
      'link-mobile':
        "If you would like to reschedule, please apply <a href='{href}' target='_blank' rel='noopener noreferrer'>here.</a>"
    }
  },
  alternativeCandidates: {
    confirmationModal: {
      body: 'A recipient suggested other slots.<br>Please confirm the date and time in confirming page.',
      title: 'Confirm time slot?'
    },
    dropdown: {
      requestDetail: 'Ask organizer to share alternate times',
      requestTitle: 'Request',
      suggestDetail: 'Suggest your available times',
      suggestTitle: 'Suggest'
    },
    inRequestingMessage: 'You have already suggested an alternate time.',
    modal: {
      confirmedAnnotation: 'There is no need to further contact the attendees.',
      mail: 'Input email',
      otherEmail: 'Send an invitation to another calendar',
      otherEmailDescription: 'This email will receive calendar invitation and scheduling notification.',
      requestDetail: {
        notSignIn: 'Please enter email to confirm.',
        signIn: 'Please select a calendar for invitation to confirm.'
      },
      requestedByConfirmer: {
        detail: 'Alternative date will be coming soon.',
        title: 'Request sent'
      },
      requestTitle: 'Request alternate times?',
      suggestDetail: {
        notSignIn: 'Please enter email to confirm.',
        signIn: 'Select calendar to confirm.'
      },
      suggestedByOrganizer: {
        detail: 'Your suggestion has been notified in email.',
        title: 'Suggestion sent'
      },
      suggestTitle: 'Would you like to suggest an alternative date?'
    },
    toastMessage: 'Drag on the calendar to select a time slot'
  },
  app: {
    update: 'Update',
    updateAvailableMessage: 'New version is available.'
  },
  availabilitySharing: {
    attendee: {
      and: 'Collective',
      or: 'Round robin'
    },
    confirmationForm: {
      description: 'Form for confirmation',
      message: {
        description: common.messageToOrganizer,
        tooltip: 'Show a message box to let recipients leave you a message'
      }
    },
    createButton: {
      teamAndPrivateSelector: {
        label: 'Select personal or team calendar to create template.'
      },
      teamPrivateSelector: {
        nextButton: 'Next'
      }
    },
    description: 'Share template link',
    editLabel: 'Template settings',
    extractCalendarConfirm: {
      attendee: {
        body: 'Do you want to remove this attendee from the event?',
        header: 'Remove attendee?'
      },
      organizer: {
        body: 'Do you want to remove the organizer from the event?',
        header: 'Remove organizer?'
      }
    },
    form: {
      attendee: {
        helpText: 'Do not add an attendee',
        label: 'Attendees',
        placeholder: 'Add an attendee'
      },
      attendingMembers: {
        help: '(Optional) Add email addresses to invite people',
        label: 'Attendees',
        placeholder: 'Add an attendee',
        notice:
          "<a class='support-page-link' href='https://help.spirinc.com/en/how-do-i-display-searchable-google-workspace/microsoft-calendars-on-spir' target='_blank' rel='noopener noreferrer'>Link calendars</a> to add people"
      },
      candidateDescription: {
        help: '(Optional) Add any details attendees should know about the event',
        label: 'Description',
        placeholder: 'Add a description'
      },
      conditionForExtract: {
        end: 'Date range',
        label: 'Suggestion criteria',
        max: 'Max events per day',
        start: 'Start date'
      },
      confirmationForm: {
        active: {
          off: 'Hide',
          on: 'Show'
        },
        required: {
          off: 'Optional',
          on: 'Required'
        }
      },
      confirmMessage: {
        label: 'Message box',
        placeholder: 'Add a message...'
      },
      eventTitle: {
        help: 'The title your attendees see. This becomes the event title once the event is confirmed.',
        label: 'Public title',
        placeholder: 'Add a title'
      },
      exception: {
        addButton: 'Add a date',
        description: 'You can exclude specific date from availability.',
        emptyList: 'There are no excluded dates',
        exclude: 'Exclude',
        excludeList: 'List of excluded dates',
        help: 'You can also exclude dates and times by dragging on the calendar',
        holidayExclude: 'Exclude',
        holidayObserved: 'Substitute holiday',
        include: 'Add',
        label: 'Excluded dates and times',
        noException: 'You can also exclude dates and times by dragging on the calendar',
        set: 'Set'
      },
      extractCalendar: {
        attendeeLabel: 'Attendees',
        attendeeLabelForOr: 'Attendees',
        help: 'Select calendars to find availabilities from',
        label: 'Show availabilities from',
        organizerLabel: 'Organizer',
        otherCalendarLabel: 'Other calendar(s)',
        placeholder: 'Add a calendar'
      },
      holidayException: {
        addButton: 'Select a country',
        addButtonDisabled: 'You can select up to {count} countries.',
        country: 'Holidays in {country}',
        help: 'Exclude national holidays from your availability',
        label: 'Exclude holidays'
      },
      panels: {
        basicInfo: {
          inputAdditional: 'Set other items of basic information.',
          label: 'General'
        },
        condition: {
          inputAdditional: 'Detailed settings for time slots',
          label: 'Time slots and availability'
        },
        memberPick: {
          inputAdditional: 'Add items',
          label: 'Organizer'
        },
        organizerAndAttendee: {
          inputAdditional: 'Select calendars to find availabilities from',
          label: 'Organizer settings'
        },
        targetCalendars: 'Set the calendar to consider the appointment.'
      },
      title: {
        help: 'A title visible only to you',
        label: 'Private title',
        placeholder: 'Add a title'
      },
      webMeeting: {
        disabled: 'Do not set',
        enabled: 'Set',
        enabledDescriptions: {
          description:
            "Spir will issue video call link according to this priority order. If assigned organizer doesn't have prioritized tool, Spir select next priority.",
          label: 'Priority'
        },
        label: 'Video call'
      },
      weekdayCondition: {
        editButton: 'Set your weekly availability',
        label: 'Available settings'
      },
      confirmationPage: {
        label: 'After confirming the event',
        dropdown: {
          spir: 'Default confirmation page',
          external: 'Redirect to an external site'
        },
        externalConfirmationPageSetting: {
          urlInput: {
            label: 'Redirect to',
            placeholder: 'Website URL'
          },
          passEventDetails: 'Pass event details as URL parameters',
          passEventDetailsHelpText: `Attach event details, such as dates and attendees, as parameters to the redirect URL.</br>This enables you to customize or take actions on your website based on the provided details.`
        }
      }
    },
    list: {
      card: {
        action: {
          copyLink: 'Copy link',
          delete: 'Delete',
          edit: 'Edit',
          showCandidates: 'Show availability',
          showShareModal: 'Share'
        },
        helpPublishSetting: 'Turn on public access to use this template'
      },
      create: 'Create',
      help: {
        message: 'Templates are reusable links that show your available times based on preset details.',
        usage: '</br>What are templates?'
      },
      messages: {
        blank: {
          description: 'Templates are reusable links that show your available times based on preset details.',
          title: "Let's start planning"
        }
      }
    },
    messages: {
      error: {
        AVAILABILITY_UPDATED: 'There is another event scheduled at the same time',
        PROCESS_TIMEOUT: 'Looks like the server is taking to too long to respond. Please try again soon',
        DISCONNECTED_ZOOM_INTEGRATION: 'Zoom is disconnected',
        EXCEED_MAX_COUNT: "You can't create more than {count} templates",
        EXCEED_MAX_PUBLISHED_COUNT: "You can't have more than {count} templates in public access",
        CUSTOM_TITLE_FOR_INDIVIDUAL_EVENTS_TEMPLATE_ITEM_INVALID:
          'The custom event title has been reset to default because the form linked to this template was modified.',
        CUSTOM_TITLE_FOR_INDIVIDUAL_EVENTS_TEMPLATE_INVALID:
          'The custom event title has been reset to default because the form linked to this template was modified.'
      }
    },
    span: 'Buffer',
    title: 'Template',
    weekdays: { ...common.weekdays },
    weekPicker: 'Set your weekly availability'
  },
  buttons: {
    aboutDetail: 'See details',
    aboutSpir: 'About Spir',
    accountReconnect: 'Reconnect',
    add: 'Add',
    addCalendar: 'Select {type} account to connect',
    addCalendarSide: 'Add a calendar',
    addCalendarWith: 'Add calendar from {type}',
    allDay: 'All day',
    back: common.back,
    backToHome: 'Back to home',
    backToTop: 'Back to top',
    cancel: common.cancel,
    change: 'Edit',
    checkFromCalendar: 'Go to calendar view',
    confirm: common.confirm,
    confirmCandidates: 'Confirm event',
    confirmSchedule: common.confirmSchedule,
    confirmScheduleDetail: 'Confirm the date and time',
    connect: 'Connect',
    copyAvailabilityUrl: 'Copy template link',
    copyMailText: 'Copy rich text',
    copyMailTextDetail: 'Copy link text',
    copyScheduleLink: 'Copy event link',
    copyShareHTML: 'Copy rich text',
    copyShareURL: 'Copy link',
    copyShareURLDetail: 'Copy link',
    copyUrl: 'Copy link',
    create: 'Create',
    createEdit: 'Create/Edit {type}',
    createEvent: 'Create event',
    createFrom: 'Create from {type}',
    delete: 'Delete',
    deleteAll: 'Delete all',
    detail: 'Detail',
    discard: 'Discard',
    disconnect: 'Disconnect',
    edit: 'edit',
    editing: 'Edit',
    JoinOnlineMeeting: 'Join {meetingType}',
    loginWith: 'Log in with {type}',
    next: 'Next',
    noAvailableCandidate: common.noAvailableCandidate,
    ok: 'OK',
    preview: 'Preview',
    previewConfirmation: 'Recipient view',
    privacyPolicy: 'Privacy Policy',
    proceedSelectCandidateDate: 'Select available slots',
    profile: 'Profile',
    removeCalendar: 'Disconnect account',
    requestOrSuggestAlternativeCandidates: 'Request or suggest alternatives',
    save: 'Save',
    select: 'Select',
    selectFromCalendar: 'Go to calendar',
    selectFromList: 'Go to list',
    selectGoogleAccount: 'Select Google or Microsoft account to connect',
    settings: 'Settings',
    share: 'Share',
    shareCreatedLink: 'Share {type} link',
    showAll: 'Other ({count} people)',
    showAuthModal: 'Sign up',
    showAuthModalForPreview: 'Get started for free',
    showAuthModalFromCalendarListPanel: 'Check your availability',
    showConfirmation: 'Review',
    showMore: 'Show all',
    showOtherCandidates: 'Show other available slots',
    showScheduleDetailsForm: 'Add more settings',
    signOut: 'Log out',
    startOrLoginWith: 'Start with {type}',
    startSpir: 'Get started now',
    startWith: 'Sign up with {type}',
    submit: 'Submit',
    suggestAlternativeCandidates: 'Suggest an alternate time',
    terms: 'Terms of Use',
    update: 'Save',
    updateProfile: 'Edit profile',
    voteToAvailableCandidates: 'Vote to group poll'
  },
  calendar: {
    month: '{month}',
    year: '{year}'
  },
  calendarList: {
    calendarColor: {
      applyingTarget: 'The color will be saved in Spir only',
      pickingColorButton: 'Save',
      pickingColorNote: 'Select a background color for this calendar.',
      pickingColorTitle: 'Calendar color'
    },
    teamCalendars: {
      changeCalendarConfirmBody: '{scheduleCount} unconfirmed events organized in this calendar will be deleted.',
      confirmChange: 'Change primary calendar?',
      title: common.teamCalendars
    }
  },
  checkbox: {
    dontShowNextTime: "Don't show again"
  },
  confirms: {
    deleteCandidate: {
      body: 'The time slot will be deleted from your availability.',
      header: 'Delete time slot?'
    },
    headers: {
      pleaseInputEmail: 'Enter your email',
      pleaseSelectCalendar: 'Select a calendar'
    },
    link: {
      publishLater: 'Spir will issue a video call link.'
    },
    update: {
      body: 'Your changes will be saved.',
      header: 'Update changes?'
    },
    voteIsExist: {
      body: 'Would you like to edit your response?',
      header: 'You have already responded'
    }
  },
  createMode: {
    create: 'Create',
    menus: {
      arrangement: {
        label: 'Suggest dates and times',
        labelWithSomeone: 'Create event with someone',
        tip: {
          line1: 'Create a link to schedule.',
          line2: 'This link is one-time link for scheduling.'
        }
      },
      availabilitySharing: {
        label: 'Share availability from templates',
        tip: {
          line1: 'Share your availability from your template link.',
          line2: 'This is a permanent link.'
        }
      },
      event: {
        label: 'Create event',
        labelByMySelf: 'Create event by myself',
        message: 'Schedule a new event on your calendar'
      }
    },
    sections: {
      event: 'Calendar',
      schedule: 'Scheduling'
    },
    title: "Let's schedule an event"
  },
  description: {
    addCalendarForInAppBrowser: {
      message: 'No calendar connected. Connect a calendar in a browser (Safari, Chrome, etc.)',
      title: 'Unsupported browser'
    },
    availabilitySharing: {
      noCandidates: {
        message1: 'There are no available slots to select from.',
        message2: 'Please contact the organizer.'
      }
    },
    linkText: ' here',
    noAvailableCandidates:
      'There are no available options at this time. Please check again tomorrow or inquire with the organizer.',
    preview: {
      caution: 'Only you will see your calendar.',
      desc: 'Sign up to overlay these time slots on your own calendar.',
      title: 'Let Spir find the best date and time for you'
    },
    scheduleMark: {
      firstLine: 'You can add time slots by dragging on the calendar or clicking the add button.',
      secondLine: 'Spir automatically prevents double-booking.'
    },
    signInLink: 'Log in',
    signUp: 'Link your calendars to easily schedule events with others.',
    signUpAfterConfirmed: {
      // TODO: Translation
      appeal:
        'Spir is a service that works with Google Calendar and Outlook to make it easy to coordinate dates.\nOnce registered, you can easily adjust your schedule while checking your own calendar.',
      description:
        'Spir is an intelligent executive assistant to help you manage all your bookings and multiple calendars in one place.',
      header: 'Your personal executive assistant'
    },
    signUpFromConfirm: 'Link your calendars to easily schedule events with others.',
    signUpFromConfirmForInAppBrowser: 'Sign up from a browser (Safari, Chrome, etc.)',
    signUpLink: 'If you don’t have Spir account, please sign up',
    teamInvitedSignInInfo: {
      link: 'here',
      message: "If you don't have an account yet {0}"
    },
    teamInvitedSignUpInfo: {
      link: 'here',
      message: 'If you already have an account {0}'
    },
    useDefaultBrowser: {
      signIn: {
        messageHtml:
          "You can't <strong>log in</strong> in this browser.</br>Login from a browser (Safari, Chrome, etc.)"
      },
      signUp: {
        messageHtml:
          "You can't <strong>sign up</strong> in this browser.</br>Sign up from a browser (Safari, Chrome, etc.)"
      },
      messageHtml:
        "You can't <strong>sign up or log in</strong> in this browser.</br>Login or sign up from a browser (Safari, Chrome, etc.)"
    }
  },
  displayItem: {
    DateWithTimezoneAndEditButtons: {
      cancel: 'Cancel',
      readjustment: 'Readjustment'
    }
  },
  event: {
    form: {
      panels: {
        others: {
          inputAdditional: 'Add details'
        }
      },
      title: {
        label: 'Title',
        placeholder: 'Add a title'
      }
    },
    list: {
      toast: {
        cannotShowDeletedMemberEvent: 'Event organizer deleted'
      }
    }
  },
  forms: {
    auth: {
      addCalendar: {
        explanation: {
          aboutDataHandling: {
            caption: 'How is my data handled?',
            description:
              'Your calendar data, other than new events you create in Spir, is not stored on our servers. No one can see your calendar unless you share it.'
          },
          aboutPermission: {
            caption: 'Why does Spir need calendar access?',
            description: 'We need to view and edit your calendar in order to show your availability and add new events.'
          }
        },
        selectCalendar: 'Select calendar to connect',
        title: 'Select a calendar to connect'
      },
      noSupport: {
        title: 'Unsupported browser'
      },
      signIn: {
        title: 'Log in'
      },
      signUp: {
        title: 'Sign up'
      }
    },
    common: {
      arrangementType: {
        poll: 'Group poll',
        schedule: 'Event'
      },
      attendee: {
        label: 'Attendee',
        placeholder: 'Add an attendee'
      },
      bufferTime: {
        label: 'Buffer before and after event',
        placeholder: 'Save free time before and after the event'
      },
      candidate: {
        label: 'Available time slots'
      },
      collapseButtonTitle: {
        candidateTargetCalendarsSettings: {
          title: 'Calendars to find availabilities from'
        },
        notificationSettings: {
          title: 'Notification settings'
        },
        timezoneAndExceptions: {
          title: 'Time zone and exceptions'
        }
      },
      description: {
        label: 'Description',
        placeholder: 'Add a description'
      },
      duration: {
        label: 'Duration',
        placeholder: 'Select duration'
      },
      location: {
        label: 'Location',
        placeholder: 'Add a location'
      },
      maxNumPerDay: {
        label: 'Max events per day'
      },
      meetingRoom: {
        label: 'Meeting room',
        placeholder: 'Add a meeting room'
      },
      offsetFromStartDate: {
        help: 'Spir will find availabilities up to three months from the start date',
        label: 'Range'
      },
      organizer: {
        label: 'Calendar'
      },
      sectionTitle: {
        attendee: 'Attendees',
        basicInfo: 'General',
        candidate: 'Availability',
        itemsAfterConfirmation: 'Messaging',
        others: 'Details', // TODO: QUESTIONNAIRE_FEATURE
        details: 'Details',
        targetType: '\bPersonal or team'
      },
      startDate: {
        label: 'Start date and time'
      },
      targetCalendars: {
        label: 'Calendars',
        placeholder: 'Add a calendar'
      },
      timeZone: {
        label: 'Time zone'
      },
      title: {
        label: 'Title',
        placeholder: 'Add a title'
      },
      visibility: {
        label: 'Visibility',
        placeholder: 'Select privacy settings'
      },
      transparencyStatus: {
        label: 'Transparency',
        placeholder: 'Select transparency settings'
      }
    },
    condition: {
      bufferTime: 'Buffer',
      candidateCount: '{count} slot(s)',
      candidateCountLabel: 'Possible dates',
      error: {
        noRules: 'Time not selected'
      },
      isPublished: 'Public',
      label: 'Auto suggest settings',
      maxNumPerDay: 'Max events per day',
      period: 'Selected time'
    },
    event: {
      addAccount: 'Add accounts',
      addDescription: 'Add description',
      addLocation: 'Add location',
      addMeetingRoom: 'Add meeting room',
      allDay: 'All day',
      date: 'Event time',
      deletedMark: 'Deleted event',
      duration: '{min} min',
      durationHour: '{hour} hours',
      title: 'Title',
      visibility: {
        default: 'Default',
        private: 'Private',
        public: 'Public'
      },
      transparencyStatus: {
        free: 'Free',
        tentative: 'Tentative',
        busy: 'Busy',
        oof: 'Oof',
        workingElsewhere: 'WorkingElsewhere',
        unknown: 'Unknown'
      }
    },
    sections: {
      arrangementTypeRadioButton: {
        poll: {
          description: 'Multiple people vote on dates and times. Organizer confirms the final time.',
          title: 'Group poll'
        },
        schedule: {
          description: 'Organizer suggests dates and times. Attendees confirm.',
          title: 'Event'
        }
      },
      peekCandidates: {
        autoCandidateNotice: {
          tipMark: 'Find availabilities from your calendar',
          title:
            "Spir automatically prevents <a class='double-booking-link' href='{href}' target='_blank' rel='noopener noreferrer'>double-booking</a>."
        },
        changeModeButton: {
          label: 'Auto suggest'
        },
        list: {
          addButton: common.addPossibleDates,
          empty: 'No possible dates'
        },
        title: 'Available dates and times'
      },
      peekCandidatesForm: {
        header: 'Auto suggest',
        runPeekButton: 'Apply'
      }
    }
  },
  globalNavigation: {
    other: 'Other',
    arrangementsOnCalendar: 'Calendar',
    close: 'Close',
    createSchedule: 'Suggest available slots',
    help: 'Help',
    home: 'Home',
    list: 'Scheduling list',
    menu: 'Menu',
    notification: 'Notifications',
    open: 'Open',
    setting: 'Settings',
    support: 'Support'
  },
  groupPoll: {
    candidate: 'Time slots',
    candidateTitlePrefix: '(Poll) '
  },
  input: {
    placeholder: {
      mail: 'Enter email',
      search: 'Search'
    }
  },
  inviteMemberModal: {
    addMember: 'Add a member to invite',
    description: 'Enter the email addresses of the attendees',
    mailTextInput: {
      label: 'email address',
      placeholder: 'email@example.com'
    },
    sendMail: 'Send an invitation email',
    title: 'Invite member'
  },
  labels: {
    allDay: 'All day',
    attendee: 'Attendees',
    attendees: 'Attendee(s)',
    begin: 'Start',
    calendarOnConfirm: 'Calendar',
    candidate: 'Time slots',
    company: 'Company name',
    create: 'Create new',
    date: 'Date',
    dateTime: 'Date',
    duration: 'Duration',
    email: 'Email',
    end: 'End',
    endDateTime: 'End',
    eula: 'Terms of Use',
    event: 'Events',
    holiday: {
      countries: {
        au: 'Australia',
        br: 'Brazil',
        ca: 'Canada',
        cn: 'China',
        de: 'Germany',
        fr: 'France',
        gb: 'United Kingdom',
        hk: 'Hong Kong',
        in: 'India',
        jp: 'Japan',
        kr: 'South Korea',
        sg: 'Singapore',
        th: 'Thailand',
        tw: 'Taiwan',
        us: 'United States'
      }
    },
    inputSomething: 'Please enter {attr}',
    invalidAccount: 'Invalid account',
    lastUpdatedAt: 'Date updated',
    list: '{Type} list',
    meetingId: 'Meeting ID',
    meetingRoom: 'Meeting room',
    multiple: '...',
    myCalendars: 'My calendars',
    name: 'Name',
    newEvent: 'New event',
    none: 'None',
    notInputted: 'No title',
    notSet: 'Not set',
    noVotes: 'No responses',
    one_day: 'Day',
    one_week: 'Week',
    optional: 'Optional',
    organizer: 'Organizer',
    otherCalendars: 'Other calendars',
    participant: 'Attendees',
    participantsCount: '{count} attendee(s)',
    password: 'Password',
    pattern: 'Pattern',
    poll: 'Group poll',
    poweredBy: 'Powered by',
    previewRequestConfirm: 'Recipient view',
    privacyPolicy: 'Privacy Policy',
    publish: 'Public access',
    rejected: '(Declined)',
    requestConfirm: 'Adjust date and time',
    requestPoll: 'Send poll',
    schedule: 'Event',
    selectAccount: 'Select an account',
    spir: 'Spir',
    startDateTime: 'Start',
    suggestAlternativeCandidates: 'Suggest alternate times',
    three_days: '3 days',
    time: {
      hour: '{hour} hour',
      minute: '{min}'
    },
    displayDuration: {
      hour: '{duration} hour | {duration} hours',
      min: '{duration} min'
    },
    timePeriod: 'Date',
    title: 'Title',
    today: 'Today',
    url: 'Link',
    visibility: 'Visibility',
    transparencyStatus: 'Transparency',
    vote: {
      no: 'Decline',
      yes: 'Accept'
    },
    votedPeople: 'Poll results',
    voters: 'Responses'
  },
  message: {
    addAttendee: '(Optional) Invite attendees',
    addedConfirmedEvent: 'The calendar has been registered.',
    attendeeNoResultInfo: 'Enter an email address of the attendee',
    betterCommunication: 'By sharing your availability, communication can become smoother.',
    cancelToCheckEvents: 'Release the schedule confirmation.',
    cannotAddCandidateInPollEdit: "You can't add or edit time slots here",
    changeOnlineMtgUnavailable: 'The calendar has been updated so the method for the video call has also been updated.',
    commonSuccess: 'Complete',
    confirm: {
      attendee: {
        add: 'Add',
        emailHelper: 'Enter your Google or Microsoft account email to sync events',
        emailPlaceholder: 'email@example.com',
        namePlaceholder: 'Steve from Acme, Inc.',
        title: 'Other attendees'
      },
      attendeeInputSubLabel: 'Other attendees',
      calendarSelectLabel: 'Confirm event',
      calendarSelectSubLabel: 'My calendar',
      confirmWithPollCount: 'Submit poll?',
      desc: 'Select and confirm the calendar.',
      doNotNeedToSendEmail: 'There is no need to further contact the organizer.',
      notSignIn: 'Please input and confirm your email address.',
      otherCalendar: {
        add: 'Add',
        emailHelper: 'Spir will send an invite to this email address',
        emailPlaceholder: 'email@example.com',
        title: 'Add another calendar'
      },
      pleaseInputYourInfo: 'Confirm booking',
      pleaseInputYourInfoSubLabel: 'Edit your profile',
      sentToOrganizer: 'Your vote has been notified in email to organizer.',
      sentToVotedUsers: 'This event has been notified to all voters.',
      title: 'Confirm this date and time?',
      useAttendee: 'Add an attendee',
      useOtherCalendar: 'Add another calendar'
    },
    confirmBySpecificDate: 'Would you like to confirm this date and time?',
    confirmCandidates: 'Confirm {length} available slots',
    confirmDelete: 'Delete this event?',
    confirmDeleteAccountDescription:
      'All data in this account will be removed from Spir.\n• Scheduling\n• Group poll\n• Templates\nAre you sure you want to disconnect this account?',
    confirmDeleteAccountTitle: 'Disconnect this account?',
    confirmDeleteCandidateTitle: 'Delete this time slot?',
    confirmDeleteWholeTitle: 'Delete this {type}?',
    confirmDiscard: 'Discard unsaved changes?',
    confirmedCandidate: 'The schedule has been confirmed.',
    confirmedVote: 'Your vote has been confirmed.',
    copiedToClipBoard: 'Link copied',
    copiedToClipBoardFailure: 'Could not copy to clipboard',
    createdSchedule: 'Your event is ready to share',
    deleteAccountFailByPrimaryCalendar: "You can't disconnect the primary browser.",
    deleteCandidateConfirm: "The selected time slots from {type} will be deleted. <br> You can't undo this action.",
    deleteConfirm: "No remaining slots. <b>{type}</b> will be deleted. <br> You can't undo this action.",
    deleteLastCandidate: 'Could not find results',
    deleteOperationWarning: 'Any unsaved changes will be lost.',
    disabledDueToAttendee: "Can't set attendees",
    doYouWantToUpdateProfile: 'Update profile?',
    dragToAddCandidates: 'Edit time slots by dragging on the calendar',
    emailChange: {
      failed: 'The email address could not be changed. Please try again.',
      requireLoginAgain: 'Log in again',
      success: 'We have sent you a verification email. Please check your inbox.'
    },
    error: {
      404: 'Page not found',
      accountAlreadyExists: 'This account is connected to a different Spir account',
      accountExistsInAnotherCredential:
        'An account with this email address already exists. Log into the existing account.',
      addCalendar: {
        accountDuplicated: 'This account is connected to a different Spir account',
        failInGoogleAuth: 'You need to accept cookies to use Spir',
        notEnoughScope: 'You need to allow calendar access to use Spir',
        unknown: 'Unknown error'
      },
      arrangementType: {
        failedToFetchTeamRecord: 'Could not get team info'
      },
      atLeastOneCalendar: 'You need at least one calendar to use Spir',
      auth: {
        accountDuplicated: 'This account is connected to a different Spir account',
        accountExistsInAnotherCredential:
          'An account with this email address already exists. Log into the existing account.',
        canceled: 'Connection canceled',
        failInGoogleAuth: 'You need to accept cookies to use Spir',
        notEnoughScope: 'You need to allow calendar access to use Spir',
        noUser: 'You need to sign up to use Spir',
        unknown: 'Unknown error'
      },
      candidateError: {
        cannotUpdateShortThenDuration: "Time slots can't be shorter than the event length",
        endMustAfterStart: "The end time can't be after the start time",
        intersection: "Time slots can't overlap",
        mustBeBeforeNow: "You can't select a past date",
        durationExceedsLimit: "Time slot can't be over 24 hours"
      },
      checkForm: 'Invalid input',
      confirmed: 'This event has been confirmed',
      deleted: 'This event will be deleted',
      duplicated: 'Time already chosen',
      exceedMacLength: 'This text is too long',
      invalid: 'Invalid {attr}',
      invalidInvitationCode: 'Invalid invitation code',
      mustBeBeforeNow: "You can't select a past date",
      noCandidateInRange: 'Time slot out of range',
      noPoll: 'There are no group polls',
      noPrimaryCalendar: 'Invalid calendar',
      noSchedule: 'There are no events',
      notEnoughScope: 'Check the boxes "Display and edit all calendar events" and "Display calendar"',
      notSupported: 'Invalid {value}',
      onlineMeetingToolDisconnected: 'Zoom disconnected',
      pastEvent: 'This event occurred in the past',
      pleaseLogin: 'This account already exists. Log into the existing account.',
      required: '{attr} required',
      serverError: 'Server error',
      validation: {
        excluded: '{attr} duplicated',
        emailExcluded: '{attr} duplicated',
        invalid: 'Invalid {attr}',
        max: "Text can't be longer than {length} characters",
        min: "Text can't be shorter than {length} characters",
        required: 'Enter {attr}',
        phoneNumber: 'Enter a correct phone number',
        requiredAtLeastOneCheck: common.requiredAtLeastPurposeCheck,
        requiredExternalConfirmationPageUrl: 'Enter a URL',
        httpsOnly: 'The URL must start with https'
      },
      notFoundSlackCallbackState: 'Not found Slack data', // TODO: Translation
      teamNotAvailable: 'Could not access team.'
    },
    errorCommon: 'An error occurred',
    fromSelectedCalendars: 'Get from selected calendars',
    havePlan: 'Busy',
    holidayExclusionDisconnect: {
      description: 'The selected holidays will be shown as available in your calendar.',
      title: 'Include holidays in {country} in your availability'
    },
    inputYourEmailAndConfirm: 'Please confirm your email address.',
    meetingRoomsRefreshed: 'The meeting rooms have been reset because the organizer has changed.',
    noAttendee: 'No responses',
    noPattern: 'This is not {type}.',
    noResult: 'No results',
    notAllowedCalendar: "This calendar can't be edited",
    noTitle: 'No title',
    onlineMtgUpdated: 'The calendar has been updated so the method for the video call has also been updated.',
    overMaxCandidate: "You can't have more than {count} time slots",
    pleaseConfirmFromBelowUrl: 'Select time slots from the following link.',
    pleaseSelect: 'Please select',
    pleaseSelectAvailableCandidate: 'Select dates and times',
    pleaseSelectCandidates: common.pleaseSelectCandidates,
    pleaseWaitForSync: 'Please wait as the calendar is synced.',
    pollOverview: {
      header: 'Select your available times',
      subHeader: 'Answer this poll by selecting your available dates and times.'
    },
    preview: {
      notAllowedConfirm: "You can't confirm slots in the preview",
      notAllowedRequest: "You can't respond in the preview",
      notAllowedRequestAlternativeCandidates: 'This is a preview'
    },
    profileUrlCopied: 'Profile link copied',
    removedOverDurationCandidate: 'Slots shorter than the event duration have been deleted.',
    removeOverDurationCandidate: {
      body: 'Time slots shorter than the new duration will be deleted.',
      header: 'Change event duration?'
    },
    scheduleOverview: {
      header: 'Select a date and time',
      subHeader: 'The organizer asked you to select a date and time for the event.'
    },
    searchNoResultsFound: 'No results found.',
    selectCalendar: 'Select a calendar to register.',
    selectDate: 'Drag on the calendar to select a time slot',
    selectedCandidateIsInvalidPleaseSelectAgain: 'This time slot is no longer valid. Please select another time slot.',
    sentEmail: 'The email has been sent.',
    shareTitle: 'Share Spir link',
    success: {
      addedCalendar: 'Calendar added',
      created: '{type} created',
      deleted: '{type} deleted',
      deleteEvent: 'Event successfully deleted',
      saved: 'Changes saved',
      savedEvent: 'Event saved',
      savedSchedule: 'Updated availability',
      updated: '{type} changed'
    },
    tapToCheckEvents: 'Tap to check events.',
    userSafari: 'Copy the link and open it in Safari.',
    vote: {
      confirm: 'Submit poll?',
      subTitle: 'You have accepted {yesCount} slots and declined {noCount} slots.'
    },
    youCanCreateByClick: 'You can create {type} using the Create button to the upper right.',
    checkCandidatesOnCalendar: common.checkCandidatesOnCalendar,
    showOnlyCandidates: 'Show slots only'
  },
  modals: {
    addCalendar: {
      title: 'Add a calendar'
    },
    arrangementTypeChangeConfirmation: {
      body: 'Any unsaved changes will be lost.',
      changeToPersonalPoll:
        "Since 'Group poll' is not available for teams, Spir will switch to personal 'Group poll'. Any unsaved changes will be lost.",
      changeToTeamSchedule:
        "Since 'Group poll' is not available for teams, it will switch to 'Scheduling' for teams. Any unsaved changes will be lost.",
      title: 'Change scheduling type?'
    },
    autoPeekCandidatesInfo: {
      body: "When you suggest times, Spir will select possible dates automatically based on auto suggestion settings.<br />If you prefer manual select, please change default settings from <a class='setting-page' href='{href}'>settings</a>.",
      title: 'Suggest available times'
    },
    cancelSchedule: {
      description: 'Tell the organizer why you are cancelling the schedule.',
      name: 'Reason for cancellation',
      placeholder: 'Add a message...',
      title: 'Cancel event'
    },
    signUp: {
      title: 'Join Spir to view the calendar'
    }
  },
  myInfo: {
    mine: 'You'
  },
  notFound: {
    availabilitySharing: {
      desc: "The page couldn't be found. The link either doesn't exist or is no longer valid.",
      title: 'Page not found'
    },
    default: {
      desc: ' ',
      title: 'Page not found'
    }
  },
  notifications: {
    daysAgo: '{elapsed} days ago',
    GROUP_POLL_ADD_VOTE: '{username} voted on dates of {scheduleTitle}',
    hoursAgo: '{elapsed} hours ago',
    minutesAgo: '{elapsed} minutes ago',
    monthsAgo: '{elapsed} months ago',
    readMore: 'Read more',
    secondsAgo: '{elapsed} seconds ago',
    title: 'Notification',
    yearsAgo: '{elapsed} years ago'
  },
  onlineMeetings: {
    googleMeet: 'Google Meet',
    joinTo: 'Join {type}',
    microsoftTeams: 'Microsoft Teams',
    none: 'None',
    team: {
      NotSet: 'なし',
      Set: 'あり'
    },
    zoom: 'Zoom'
  },
  onlineMeetingToolModal: {
    accountLabel: 'Connected account',
    annotation: 'To connect a different account, first disconnect the current account.',
    title: 'Settings'
  },
  peekCandidates: {
    checkboxToSave: 'Save settings',
    dateOptions: {
      custom: 'Until a specific date',
      customStart: 'From a specific date',
      five_days: 'For 5 days',
      five_days_later: 'In 5 days',
      five_hours_later: 'In 5 hours',
      four_days: 'For 4 days',
      four_days_later: 'In 4 days',
      four_hours_later: 'In 4 hours',
      four_weeks: 'For 4 weeks',
      four_weeks_later: 'In 4 weeks',
      next_month: 'Next month',
      next_week: 'Next Monday',
      now: 'Now',
      one_day: 'For 1 day',
      one_hour_later: 'In 1 hour',
      one_week: 'For 1 week',
      one_week_later: 'In 1 week',
      the_day_after_tomorrow: 'In 2 days',
      the_week_after_next: '1 week from Monday',
      three_days: 'For 3 days',
      three_days_later: 'In 3 days',
      three_hours_later: 'In 3 hours',
      three_months: 'For 3 months',
      three_weeks: 'For 3 weeks',
      three_weeks_later: 'In 3 weeks',
      tomorrow: 'Tomorrow',
      two_days: 'For 2 days',
      two_hours_later: 'In 2 hours',
      two_months: 'For 2 months',
      two_weeks: 'For 2 weeks',
      two_weeks_later: 'In 2 weeks'
    },
    messages: {
      canNotDragOrClickWhilePanelIsActive: "You can't drag on the calendar while in auto suggest"
    }
  },
  personalTeamSelectDropdown: {
    personal: 'Personal',
    teams: 'Team'
  },
  poll: {
    form: {
      panels: {
        others: {
          inputAdditional: 'Add items'
        }
      },
      title: {
        label: 'Title',
        placeholder: 'Add a title'
      }
    },
    list: {
      attendeeCount: {
        message: 'Responses: {count}'
      },
      duration: {
        message: '{minute} min'
      },
      durationHour: {
        message: '{hour} hours'
      },
      lastUpdate: {
        message: 'Last update: {datetime}'
      }
    }
  },
  profile: {
    avatarAndBg: 'Avatar and background image',
    company: 'Company',
    firstName: 'First name',
    introduction: 'About me',
    jobTitle: 'Title',
    lastName: 'Last name',
    name: common.name,
    unsavedCancel: {
      body: 'Any unsaved changes will be lost.',
      header: 'Discard unsaved changes?'
    }
  },
  schedule: {
    form: {
      panels: {
        notification: {
          label: 'Set notification'
        },
        others: {
          inputAdditional: 'Add items'
        }
      },
      title: {
        label: 'Title',
        placeholder: 'Add a title'
      }
    },
    list: {
      attendeeCount: {
        message: '{count} attendee(s)',
        messageWithAuthor: '{authorCount} organizer, {attendeesCount} attendee(s)'
      },
      duration: {
        message: '{minute} min'
      },
      durationHour: {
        message: '{hour} hours'
      }
    }
  },
  scheduleCreationSettings: {
    auto: 'Automatic',
    autoCandidateBtn: 'Auto suggestion',
    manual: 'Manual',
    modal: {
      addTimeRange: 'Add',
      custom: 'Custom',
      daysOfWeek: 'Days of week',
      duration: 'Duration',
      initialTime: 'Buffer',
      period: 'Suggestion period',
      periodNextMonth: 'Until the end of this month',
      periodNextWeekend: 'Until the end of next week',
      periodThisWeekend: 'Until the end of this week',
      timeRange: 'Period of time',
      timeRangeEditCancel: 'Cancel',
      timeRangeEditSave: 'Save',
      timeRangeTitlePlaceholder: 'Add a title',
      title: 'Settings'
    },
    settings: 'Conditions',
    status: {
      count: '{count} slot(s)',
      label: 'Available times'
    }
  },
  scheduleEventList: {
    add: 'Add',
    attendee: 'Attendees',
    attendManagement: {
      accepted: 'Accept',
      declined: 'Decline',
      label: 'Attendance',
      tentative: 'Maybe'
    },
    calendar: 'Calendar',
    cancel: 'Cancel',
    candidateDate: 'Time slots',
    confirmInformation: 'Learn more about scheduling',
    description: 'Description',
    duration: 'Duration',
    empty: 'Available slots will be shown',
    endDateTime: 'End',
    location: 'Location',
    meetingRoom: 'Meeting room',
    onlineMeeting: 'Video call',
    organizer: 'Organizer',
    placeholder: {
      addAccount: 'Add attendees.',
      addParticipant: 'Add an attendee',
      calendar: 'Select a calendar',
      description: 'Add notes',
      location: 'Add location',
      title: 'Untitled'
    },
    save: 'Save',
    startDateTime: 'Start',
    time: 'Date and time',
    title: 'Title'
  },
  scheduleSharingText: {
    candidatePeriod: 'Date range',
    confirmButton: 'Show other available slots',
    emptyCandidateMessage: 'No time slots',
    htmlIntroMessage: 'Please select your available slot to confirm.',
    meetingTime: 'Duration',
    plainIntroMessage: 'Can you check your calendar and see if you are open?',
    remainCandidateCountMessage: 'One more day with available slots. | {count} more days with available slots.',
    remainItemCountMessage: '...{count} remaining',
    time: {
      hour: 'hours',
      minute: 'min'
    },
    intro: '', // Notice: 使われていないため
    linkInvitation: '' // Notice: 使われていないため
  },
  scheduleTabs: {
    headers: {
      confirmed: 'Confirmed',
      filter: 'Filter',
      filterWithCount: 'Filter ({count})',
      tbc: 'Planning'
    },
    listItem: {
      today: 'Today'
    },
    calendarEvents: {
      summaryPrefix: {
        planning: '(Planning) ',
        expired: '(Expired) '
      }
    }
  },
  settings: {
    accountLink: {
      sectionTitle: 'Account settings'
    },
    arrangement: {
      items: {
        autoCandidate: {
          label: 'Activate auto suggest automatically when you start scheduling',
          tooltip: 'Uncheck the box if you want to start scheduling by selecting time slots manually'
        }
      },
      title: 'Auto suggest'
    },
    calendar: {
      header: 'View options',
      timeSelector: {
        label: 'Time format',
        latin: 'pm 1:00',
        number: '13:00'
      },
      weekSelector: {
        label: 'Start week on',
        monday: 'Monday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      }
    },
    emailChange: {
      currentEmail: 'Current email',
      newEmail: 'New email',
      sectionTitle: 'Email settings'
    },
    header: {
      title: 'Settings'
    },
    integrations: {
      account: '{accountName}',
      accountLabel: 'Connected account',
      messageForSignInToZoom: 'Sign into Zoom account?',
      passwordLabel: 'Passcode',
      passwordOptionAuto: 'Create Zoom link when an event is confirmed',
      passwordOptionConformity: 'Follow Zoom settings',
      passwordOptionConformityNotes:
        'If a meeting passcode is not required in your Zoom account, it will not be set up',
      sectionTitle: 'Connections',
      signInToZoom: 'Connect Zoom',
      zoom: 'Zoom',
      zoomConnectionError: 'Could not connect Zoom'
    },
    localization: {
      en: {
        label: 'English',
        shortenLabel: 'EN'
      },
      ja: {
        label: '日本語',
        shortenLabel: 'JA'
      },
      label: 'Language'
    },
    localizationAndTimeZone: {
      sectionTitle: 'Language and time zone'
    },
    timeZone: 'Time zone',
    translation: {
      label: 'Translation mode'
    }
  },
  shareTextModal: {
    arrangementType: {
      label: 'Arrangement'
    },
    availabilitySharing: {
      copyURLSubTitle: 'Share link',
      copyURLText: 'Copy link'
    },
    copyHTMLSubTitle: 'Add time slots to message',
    copyHTMLText: 'Copy rich text',
    copyType: {
      mail: 'Email'
    },
    copyURLSubTitle: 'Send {type} link',
    copyURLText: 'Copy link',
    helpText: 'Choose to share a link or rich text',
    scheduleTitleFromUnconfirmedList: 'Share event',
    shareAvailabilitySharing: 'Share this template',
    sharingHTMLTip: 'Recipients can directly click the time slot to confirm the date and time',
    sharingMethodText: 'Share {type}',
    sharingURLTip: 'Recipients can open this link to select from available dates and times',
    title: 'Share availability',
    url: 'link'
  },
  subscription: {
    error: {
      productOrPriceNotSelected: 'Select a plan'
    },
    price: {
      perDay: '/{day}day',
      perDays: '/{day}days',
      perMonth: '/{month}month',
      perMonths: '/{month}months',
      perWeek: '/{week}week',
      perWeeks: '/{week}weeks',
      perYear: '/year',
      yen: 'JPY'
    },
    subscriptionSuccess: 'You have successfully subscribed to {planName}'
  },
  teamInvited: {
    completeSlide: {
      description: "Setup has been completed. Let's try Spir.",
      next: 'Open Spir.',
      title: 'Setup complete'
    },
    errors: {
      etc: {
        description: ' ',
        title: 'An error occurred'
      },
      'joined-already': {
        description: ' ',
        title: 'You are already a member of this team.'
      },
      'token-expired': {
        description: 'Please ask the administrator to resend the invitation.',
        title: 'Invalid invitation link'
      }
    },
    settingIntroSlide: {
      description:
        "With Team Plan, you can flexibly share not only your company calendar with your team members, but also your private calendar or another organization's calendar. You can adjust the viewing permissions for each calendar, so you can share your events with your team while protecting your privacy.",
      next: 'Select calendars to connect',
      step: '2/3',
      title: 'Set up a calendar to link to your team'
    },
    settingSlide: {
      addCalendar: 'Add a calendar to link',
      next: 'Save and go to next',
      step: '3/3'
    },
    signUpSlide: {
      signIn: {
        description: 'If you already have a Spir account, log in with that account to join the team.',
        title: 'Log in'
      },
      signUp: {
        description: 'First of all, you need to create an account with Spir.',
        step: '1/3',
        title: 'Sign up'
      }
    },
    startSlide: {
      description: {
        firstLine: "You've been invited to be a member of the {name} team.",
        secondLine: "Let's get set up and start using Spir."
      },
      next: 'Start setup.',
      title: 'Welcome to Spir'
    }
  },
  teamMember: {
    button: {
      cancelInvite: 'Cancel invite',
      copyUrl: 'Copy invite link',
      reinvite: 'Resend invite'
    },
    deletedMember: 'Deleted member',
    memberStatus: {
      invalid: 'Calendar disconnected',
      invitationExpired: 'invitation expired',
      invited: 'inviting'
    },
    undecidedMember: 'undecided member'
  },
  teamMenu: {
    authority: {
      'admin-description': 'Admins can manage team settings',
      administrator: 'Admin',
      member: 'Member',
      'member-description': 'Members can edit personal settings'
    },
    member: {
      'delete-btn': 'Remove'
    },
    personal: {
      settingTitle: 'Personal account'
    }
  },
  teamMyCalendar: {
    addCalendar: 'Add a calendar',
    mainCalendar: {
      description:
        'In a team plan, team members can make schedules on your behalf. In that case, please select the primary calendar where the schedule is written.',
      selectLabel: 'Select primary calendar',
      title: 'Primary calendar'
    },
    shareCalendar: {
      description:
        'Please select how to share your connected calendars to your team members. You can select 3 options.',
      title: 'Shared calendars'
    },
    title: 'My calendar',
    memberIsManagingRoom: {
      header: 'You are managing a resource from this calendar',
      description:
        'Please change who manages the resource in resources settings before changing your primary calendar.',
      button: 'Manage resources',
      close: 'Close'
    }
  },
  teamVisibilityMenu: {
    freeBusyOnly: {
      description: 'Share only time frame of event with your team members.',
      label: 'Share availabilities only'
    },
    notShare: {
      description: "Team members can't see any events in this calendar.",
      label: "Don't share"
    },
    readAll: {
      description: 'Share all event details with your team members.',
      label: 'Share all events'
    }
  },
  timezone: {
    addSubTimezone: 'Add another time zone',
    displayTime: 'Time zone',
    label: 'timezone',
    timeZoneModal: {
      code: 'timeZone',
      currentLocation: 'Current location',
      displayName: 'Label',
      title: 'Time zones'
    },
    titles: {
      primary: 'Primary time zone',
      secondary: 'Sub time zone 1',
      tertiary: 'Sub time zone 2'
    },
    updateTimezone: {
      body: '<p class="update-timezone-body">The time zone being set <span class="update-timezone-body__timezone">{userTimezone}</span> is different from the <br>local time zone <span class="update-timezone-body__timezone">{userTimezone}</span> to the local time zone <span class="update-timezone-body__timezone">{localTimezone}</span>? </p>',
      cancelButton: 'Cancel',
      confirmButton: 'Change',
      header: 'The time zone you selected is different from your local time zone.'
    }
  },
  tooltips: {
    delete: 'Delete',
    edit: 'Edit',
    share: 'Copy {type} link'
  },
  links: common.links
}
